<template>
  <div>
    <DadminNavbar :online="online" @info="(v) => (showConnect = v)" />
    <TeoConnect v-show="showConnect" @online="(v) => (online = v)" />
    <div class="container">
      <component :is="currentView" />
    </div>
    <TeoFooter />
  </div>
</template>

<script>
import DadminNavbar from "./components/DadminNavbar.vue";
import TeoConnect from "./components/TeoConnect.vue";
import TeoFooter from "./components/TeoFooter.vue";

import PageHome from "./components/pages/PageHome.vue";
import PageInfo from "./components/pages/PageInfo.vue";
import MonitorApps from "./components/teo/monitor/MonitorApps.vue";
import PageCommands from "./components/pages/PageCommands.vue";
import TeoCrypt from "./components/teo/crypt/CryptDemo.vue";
import PageUsers from "./components/pages/PageUsers.vue";
import ActionGroups from "./components/ActionGroups.vue";
import ActionList from "./components/ActionList.vue";
import ItemsList from "./components/InventoryList.vue";

const routes = {
  "/": { page: PageHome, name: "Home" },
  "/apps": { page: MonitorApps, name: "Apps" },
  "/commands": { page: PageCommands, name: "Commands" },
  "/crypt": { page: TeoCrypt, name: "Crypt" },
  "/info": { page: PageInfo, name: "Info" },
  "/users": { page: PageUsers, name: "Users" },
  "/journal": { page: ActionList, name: "Journal" },
  "/action_group": { page: ActionList, name: "Action group" },
  "/action_groups": { page: ActionGroups, name: "Action groups" },
  "/items": { page: ItemsList, name: "Items" },
};

export default {
  name: "App",
  components: {
    DadminNavbar,
    TeoConnect,
    TeoFooter,
  },

  data() {
    return {
      online: false,
      showConnect: true,
      currentPath: window.location.hash,
    };
  },

  computed: {
    currentView() {
      let path = this.currentPath.slice(1);
      let parts = path.split("/").slice(1);
      let route = routes["/" + parts[0] || "/"];
      if (!route) {
        route = {
          page: PageHome,
          name: "Home",
        };
      }

      document.title = route.name;
      return route.page;
    },
  },

  mounted() {
    let that = this;

    window.addEventListener("hashchange", () => {
      that.currentPath = window.location.hash;
    });
  },
};
</script>

<style>
/* For all application */
body a {
  color: #42b983;
}
body hr {
  color: gray;
}
.description {
  font-size: small;
  margin-bottom: 10px;
}
</style>
