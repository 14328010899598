<template>
  <div>
    <!-- Action collapse content -->
    <div
      class="collapse multi-collapse mt-3 mb-3"
      :class="show ? 'show' : ''"
      id="collapseActionInput"
    >
      <!-- Card -->
      <div class="card">
        <!-- Header -->
        <div class="card-header">
          <div class="row">
            <!-- Title -->
            <h3 class="col">Actions</h3>

            <!-- Close button -->
            <div class="col p-2 text-end">
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                data-bs-toggle="collapse"
                data-bs-target=".multi-collapse"
              ></button>
            </div>
          </div>
        </div>

        <!-- Body -->
        <div class="card-body">
          <div class="description">
            This is new action group for selected game and filter.
          </div>
          <div
            v-for="(action, index) in actions"
            :key="index.id"
            class="input-group"
          >
            <!-- Add item input group -->
            <div class="input-group mb-3">
              <!-- Dropdown type of filter -->
              <button
                class="btn btn-outline-secondary"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ action.filterType }}
              </button>
              <ul class="dropdown-menu">
                <li v-for="(type, index) in inventoryTypes" :key="index">
                  <a class="dropdown-item" @click="action.filterType = type">{{
                    type
                  }}</a>
                </li>
              </ul>
              <ItemsDropdownList
                @itemId="(itemId) => (action.itemId = itemId)"
              />
              <SignsDropdown @sign="(sign) => (action.sign = sign)" />
              <!-- Filter input -->
              <input
                type="text"
                class="form-control"
                placeholder="number"
                v-model="action.filterAction"
              />

              <!-- Delete input button  -->
              <button
                :disabled="actions.length <= 1"
                class="btn btn-outline-secondary"
                type="button"
                id="removeButton"
                @click="removeAction(index)"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>

          <!-- Buttons -->
          <div class="form-group">
            <!-- Add new input button  -->
            <button
              v-if="!confirmWord"
              class="btn btn-secondary mr-2"
              type="button"
              id="addButton"
              @click="addAction()"
            >
              Add
            </button>

            <!-- Execute button -->
            <button
              v-if="!confirmWord"
              :disabled="state.state == 1"
              type="button"
              class="btn btn-secondary float-end"
              @click="
                !confirmWord ? (confirmWord = 'confirm') : (confirmWord = ''),
                  (state.state = 0)
              "
            >
              Execute
            </button>

            <!-- Confirm action -->
            <div class="form-group">
              <ActionConfirm
                class="mt-3"
                :action="confirmActions[confirmWord]"
                :fields="['action name', 'action reason']"
                @confirm="doConfirm"
                @cancel="doCancelConfirm"
              />
            </div>
          </div>

          <!-- Answer message -->
          <div v-if="state.state != 0" class="mt-3">
            <div
              v-if="state.state == 1"
              class="alert alert-warning mb-0"
              role="alert"
            >
              {{ state.executing }}
            </div>
            <div
              v-if="state.state == 2"
              class="alert alert-success mb-0"
              role="alert"
            >
              Action id
              <a :href="'#/journal/' + actionId">{{ actionId }}</a>
              {{ state.answer }}.
            </div>
            <div
              v-if="state.state == 3"
              class="alert alert-danger mb-0"
              role="alert"
            >
              Error: {{ state.error }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Action collapse button -->
    <div
      class="input-group collapse multi-collapse"
      :class="!show ? 'show' : ''"
      id="collapseActionInput2"
    >
      <!-- Add action button -->
      <div
        class="btn btn-outline-secondary border collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target=".multi-collapse"
      >
        Action
        <i class="bi bi-file-earmark-plus-fill"></i>
      </div>

      <!-- Action Id input -->
      <input class="form-control" type="text" v-model="actionId" />

      <!-- Actions list button -->
      <!-- Href to action groups list or to list of actions if action id is set -->
      <a
        class="btn btn-secondary"
        :href="actionId ? '#/journal/' + actionId : '#/journal'"
      >
        <i class="bi bi-list-ul"></i>
      </a>
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import ActionConfirm from "./ActionConfirm.vue";
import ItemsDropdownList from "./ItemsDropdownList.vue";
import SignsDropdown from "./SignsDropdown.vue";

const cmdUsersAction = "users.action/";
const cmdJournalList = "journal.list.actions/";

export default {
  name: "UsersAction",
  components: {
    ActionConfirm,
    ItemsDropdownList,
    SignsDropdown,
  },

  props: {
    filter: String, // Marshalled search filter
    game: String, // Game name
  },

  data() {
    return {
      actions: [
        { id: 1, filterType: "Items", itemId: "", filterAction: "", sign: "" },
      ],
      inventoryTypes: ["Items", "Storage"],
      nextId: 2,
      state: {
        state: 0, // 0 - not executing, 1 - executing, 2 - done, 3 - error
        executing: "Action is executing.",
        answer: "sucessfully executed",
        error: "err",
      },
      show: false,
      actionId: "",

      /** confirm action */
      showActionConfirm: false,
      confirmWord: "",
      confirmActions: {
        confirm: {
          name: "confirm",
          text: "Execute action?",
          word: "confirm",
        },
      },
    };
  },

  mounted() {
    let that = this;

    // Get actions show state from local storage
    this.getActionsShow();

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw) {
      // Process answer to cmdUsersAction command
      switch (gw.command) {
        case cmdUsersAction + that.game + "/" + that.actionId:
          that.processAnswer(gw.err);
          break;
        case cmdJournalList + "/" + that.actionId:
          that.processAnswer(gw.err);
          break;
      }
    });
  },

  beforeUnmount() {
    // Save actions show state to local storage
    this.setActionsShow();
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** Add new action to actions array */
    addAction() {
      this.actions.push({
        id: this.nextId++,
        filterType: "Items",
        filterAction: "",
        itemId: "",
        sign: "",
      });
    },

    /** Remove last action from actions array */
    removeAction(index) {
      if (this.actions.length <= 1) {
        return;
      }
      this.actions.splice(index, 1);
    },

    doConfirm(action, fieldsArr) {
      switch (action) {
        case "confirm": {
          this.sendToServer(fieldsArr);
        }
      }
      this.doCancelConfirm();
    },

    doCancelConfirm() {
      this.showActionConfirm = !this.showActionConfirm;
      this.confirmWord = "";
    },

    /** Send cmdUsersAction command */
    sendToServer(fieldsArr) {
      this.state.state = 1;
      this.sendCmdUsersAction(this.actions, fieldsArr);
      let that = this;
      setTimeout(function () {
        if (that.state.state == 1) {
          that.sendCmdJournal();
        }
      }, 10000);
    },

    sendCmdJournal() {
      this.teoweb.sendCmd(cmdJournalList + "/" + this.actionId);
    },

    /** Send cmdUsersAction command */
    sendCmdUsersAction(actions, fieldsArr) {
      // Make actions array string
      let actionArr = "";
      actions.forEach((action) => {
        if (actionArr != "") {
          actionArr += ", ";
        }
        actionArr +=
          action.filterType +
          ": " +
          action.itemId +
          " " +
          action.sign +
          " " +
          action.filterAction;
      });

      // Make request containing filter and actions array
      let actionReq = {
        req: this.filter + "; " + actionArr,
        fields: fieldsArr,
      };

      // Send request
      this.actionId = uuid.v1();
      let cmd = cmdUsersAction + this.game + "/" + this.actionId;
      this.teoweb.sendCmd(cmd, JSON.stringify(actionReq));
    },

    /** Process answer to cmdUsersAction command */
    processAnswer(err) {
      // Process error
      if (err) {
        this.state.state = 3;
        this.state.error = err;
        return;
      }

      // Process answer
      this.state.state = 2;
      window.location.href = "#/journal/" + this.actionId;
    },

    /** Get actions show state from local storage */
    getActionsShow() {
      let usersFilterStr = localStorage.getItem("usersFilter");
      if (usersFilterStr) {
        let usersFilter = JSON.parse(usersFilterStr);
        if (usersFilter.actions === true || usersFilter.actions !== false) {
          this.show = true;
        } else {
          this.show = false;
        }
      }
    },

    /** Save actions show state to local storage */
    setActionsShow() {
      let usersFilterStr = localStorage.getItem("usersFilter");
      let usersFilter = { actions: this.show };
      if (usersFilterStr) {
        usersFilter = JSON.parse(usersFilterStr);
      }

      const collapseActionsClasses = document.getElementById(
        "collapseActionInput"
      ).classList;
      usersFilter.actions = collapseActionsClasses.contains("show");

      localStorage.setItem("usersFilter", JSON.stringify(usersFilter));
    },
  },
};
</script>

<style scoped>
.mr-2 {
  margin-right: 0.5rem !important;
}
</style>
