<template>
  <!-- Dropdown type of filter -->
  <button
    class="btn btn-outline-secondary"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {{ selectedItem }}
  </button>

  <!-- List of items -->
  <ul class="dropdown-menu">
    <input
      type="text"
      class="form-control"
      placeholder="start typing itemId..."
      v-model="filter"
    />

    <div v-for="(item, key) in items" :key="key">
      <div v-if="checkFilters(item)">
        <a
          class="dropdown-item"
          @click="
            selectedItem = item.Name;
            $emit('itemId', item.Name);
          "
          >{{ item.Name }}</a
        >
      </div>
    </div>
  </ul>
</template>

<script>
const cmdItemsList = "items.list";

export default {
  name: "ItemsDropdownList",

  emits: ["itemId"],

  data() {
    return {
      items: {},
      selectedItem: "ItemId",
      filter: "",
    };
  },

  mounted() {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        that.error = gw.err;
        return;
      }

      // Process answer to commands
      switch (gw.command) {
        case cmdItemsList: {
          that.items = JSON.parse(data);
          break;
        }
      }
    });

    // Send 'cmdItemsList' command to Teonet
    this.teoweb.whenLogin(function () {
      that.teoweb.sendCmd(cmdItemsList);
    });
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    checkFilters(item) {
      // Check if item name contains filter
      if (item.Name.toLowerCase().indexOf(this.filter.toLowerCase()) == -1) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.form-control {
  position: sticky;
  top: 0;
  height: 40px;
}

.dropdown-menu {
  padding-top: 0%;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.btn-outline-secondary {
  border-right: none;
  /* border-left: none; */
}
</style>
