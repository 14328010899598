<template>
  <!-- List of actions -->
  <div>
    <!-- Title -->
    <div v-if="!groupId">
      <h1 class="mb-0">Journal</h1>
      <small class="text-secondary mb-3">This is a Journal of actions.</small>
    </div>
    <div v-else>
      <ActionGroup :action="actions.group" :error="error" :top="true" />
      <small class="text-secondary mb-3"
        >This is a list of actions in action group.</small
      >
    </div>

    <!-- Journal filter and Search button -->
    <div v-if="!groupId" class="input-group mb-3 mt-3">
      <!-- Filter input -->
      <input
        type="text"
        class="form-control"
        placeholder="filter"
        v-model="filter"
        @keydown.enter="getNextPage()"
      />

      <!-- Search button -->
      <a class="btn btn-secondary" @click="previous = ''; getNextPage()"
        ><i class="bi bi-search"></i
      ></a>
    </div>

    <!-- Error message -->
    <div v-if="error" class="alert alert-danger mt-3" role="alert">
      {{ error }}
    </div>

    <!-- List of actions -->
    <div v-else v-for="(action, index) in actions.rows" :key="index">
      <hr />
      <!-- Action -->
      <div class="mb-0">
        <!-- Title -->
        <h5>{{ index + 1 }}. {{ action.UserName }}</h5>

        <!-- Action -->
        <div>
          <small v-if="!isRemoveSave(action)">{{ action.Action }}</small>
          <small v-else>{{ action.GroupId }} in {{ action.State }}</small>
        </div>

        <!-- Group id and date -->
        <div
          v-if="!groupId && !isRemoveSave(action)"
          class="mb-2 description text-secondary"
          @click="select(action.GroupId)"
          style="cursor: pointer"
        >
          Group id: {{ action.GroupId }}<br />
          Date: {{ formatDate(action.Date) }}<br />
        </div>
        <div
          v-if="isRemoveSave(action)"
          class="mb-2 description text-secondary"
        >
          Date: {{ formatDate(action.Date) }}<br />
        </div>
      </div>

      <!-- Action Executed Description -->
      <div class="description">
        <div v-if="!isRemoveSave(action)">
          Set {{ replaceState(action.State) }}
        </div>
        <div v-if="action.Performed" class="badge badge-outline badge-success">
          Performed
        </div>
        <div v-else class="badge badge-outline badge-danger">Rejected</div>
      </div>
    </div>

    <!-- Next page button -->
    <div v-if="previous && !error" class="text-center">
      <hr />
      <a
        class="btn btn-secondary"
        :class="processGetPage ? 'disabled' : ''"
        @click="getNextPage()"
      >
        <i v-if="!processGetPage" class="bi bi-box-arrow-down"></i>
        <i v-else class="bi bi-arrow-clockwise"></i>
      </a>
    </div>
  </div>
</template>

<script>
import ActionGroup from "./ActionGroup.vue";

const cmdJournalList = "journal.list.actions/";

export default {
  name: "ActionView",

  components: {
    ActionGroup,
  },

  data() {
    return {
      groupId: "",
      actions: { rows: [], group: {} },
      processGetPage: false,
      previous: "",
      error: "",
      filter: "",
      previousHref: "",
    };
  },

  mounted() {
    let that = this;

    this.previousHref = window.location.href;

    // Add reader
    this.reader = this.teoweb.addReader(function (gw, data) {
      switch (gw.command) {
        // Process answer to cmdJournalList command with empty previous parametr
        case cmdJournalList + "/" + that.groupId: {
          that.processGetPage = false;
          that.actions.rows = [];
          that.parseAnswer(data);
          break;
        }

        // Process answer to cmdJournalList command
        case cmdJournalList + that.previous + "/" + that.groupId: {
          that.processGetPage = false;
          that.error = "";
          if (gw.err) {
            that.error = "Error: " + gw.err;
            return;
          }
          that.parseAnswer(data);

          break;
        }
      }
    });

    // Check list scroll to bottom
    window.addEventListener("scroll", this.scrollListener);

    // Get url parameters if url changed
    window.addEventListener("popstate", this.popstateListener);

    // Send command when login
    this.teoweb.whenLogin(function () {
      that.getUrlParameters();
    });
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
    window.removeEventListener("scroll", this.scrollListener);
    window.removeEventListener("popstate", this.popstateListener);
  },

  methods: {
    popstateListener() {
      this.getUrlParameters(true);
    },

    scrollListener() {
      const scrollTop = window.scrollY;
      const scrollHeight = document.body.scrollHeight;
      const clientHeight = window.innerHeight;

      if (scrollTop + clientHeight + 300 >= scrollHeight) {
        if (this.previous && !this.error && !this.processGetPage) {
          this.getNextPage();
        }
      }
    },

    /**
     * Get url parameters
     * @description Get url parameters and update current groupId and NextPage
     *              if groupId is changed
     */
    getUrlParameters(saveHistory = false) {
      const currentPath = window.location.hash;
      // Check if current path is #/action_group or #/journal
      if (!/#\/(action_group|journal)\/?/.test(currentPath)) return;

      const href = window.location.href;
      const [, page, groupId] = currentPath.split("/");
      if (groupId != this.groupId) {
        // Update current groupId and get NextPage if groupId is changed
        this.groupId = groupId;
        if (!this.groupId) {
          this.groupId = "";
        }
        this.previous = "";
        this.getNextPage();

        // Save to browser history
        if (saveHistory) {
          if (page != "journal") {
            history.replaceState({}, "", this.previousHref);
            history.pushState({}, "", href);
          }
        }
      }
      this.previousHref = href;
    },

    /**
     * Check if actions group id is RemoveSave
     * @param {Object} action - Action object
     * @returns {boolean} True if action group id is RemoveSave
     */
    isRemoveSave(action) {
      return action.GroupId === "RemoveSave";
    },

    /**
     * Parse answer from server
     * @param {string} data - Server answer
     */
    parseAnswer(data) {
      // Parse answer from server
      let actions = JSON.parse(data);

      // Save pagination
      this.previous = actions.pagination;

      // Add new actions to the list
      this.actions.group = actions.group;
      if (actions.rows) {
        // Add new actions to the list
        this.actions.rows = this.actions.rows.concat(actions.rows);
      } else {
        // Reset pagination
        this.previous = "";
      }
    },

    /**
     * Replace ", error: <nil>" with empty string
     * @param {string} val - String to replace
     * @returns {string} String with replaced value
     */
    replaceState(val) {
      return val.replace(", error: <nil>", "");
    },

    /**
     * Sends a command to the WebRTC server to get the next page of actions
     */
    getNextPage() {
      // Set flag to prevent multiple requests
      this.processGetPage = true;

      // Send command to the WebRTC server
      this.teoweb.sendCmd(
        cmdJournalList + this.previous + "/" + this.groupId,
        this.filter
      );
    },

    /**
     * Handles click on action group
     * @param {string} id - Action group id
     */
    select: function (id) {
      // If action group id is not "RemoveSave", redirect to the action group page
      if (id != "RemoveSave") {
        window.location.replace("#/action_group/" + id);
        return;
      }
    },

    /**
     * Converts a date string in the format "2006-01-02 15:04:05.000000 -0700 MST"
     * to a human-readable string in the user's locale.
     * @param {string} date - Date string to convert
     * @returns {string} Human-readable date string
     */
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
};
</script>

<style scoped>
.badge-outline {
  /* color: black; */
  --bs-bg-opacity: 1;
  border: 1px solid #999;
  background-color: transparent;
}

.badge-outline.badge-success {
  color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  border-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}
.badge-outline.badge-danger {
  color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
</style>
