<!-- Teo connection with Online info -->
<template>
  <div class="text-muted container">
    <small>
      <div class="name">name: {{ name }}</div>
      <!-- <div class="address">address: {{ address }}</div> -->
      <div class="name">teoname: {{ teoname }}</div>
      <div class="uptime">uptime: {{ uptime }}</div>
      <div class="version">version: {{ version }}</div>
      <div class="clients">clients: {{ clients }}</div>
      <div v-if="online" class="online"><a href="./">online</a></div>
      <div v-if="!online" class="offline"><a href="./">offline</a></div>
      <button
        v-if="disconnected"
        type="button"
        class="btn btn-warning"
        @click="reconnect()"
      >
        Reconnect
      </button>
    </small>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";

// Defalut teoweb commands
const cmdClients = "clients";

// This application teoweb commands
const cmdName = "name";
const cmdUptime = "uptime";
const cmdVersion = "version";
const cmdLoginUserSet = "login.user.set/";

export default {
  name: "TeoWeb",

  emits: ["online"],

  data() {
    return {
      name: "Loading ...",
      teoname: "Loading ...",
      uptime: "0",
      version: "...",
      clients: "0",
      players: "0",
      queue: "0",
      online: false,
      disconnected: false,
    };
  },

  mounted: function () {
    this.connect();
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** Connect to Teonet server. */
    connect() {
      let that = this;

      // Get this browser name from local storage
      this.teoname = uuid.v1();
      console.debug("teoname:", this.teoname);

      // Get name and version from local storage
      if (localStorage.name) this.name = localStorage.name;
      if (localStorage.version) this.version = localStorage.version;

      // Connect to Teonet proxy WebRTC server

      // Webrtc Server name
      // The server_name is defined in global variable server_name.
      // The server_name stored in server_name_sample.js file.
      // To change server_name copy file server_name_sample.js to server_name.js
      // and update the server_name constant.
      const serverName = this.server_name;

      // This server port
      const port = "8083"; // TODO: Get ws server port from some parameter

      // Signal server scheme and host name
      let host;
      let scheme;
      const hostname = "signal.teonet.dev"; // window.location.hostname;
      if (hostname == "localhost" || hostname == "127.0.0.1") {
        scheme = "ws";
        host = hostname + ":" + port;
      } else {
        scheme = "wss";
        host = "signal.teonet.dev";
      }

      // Connect to Teonet proxy WebRTC server
      this.teoweb.connect(
        scheme + "://" + host + "/signal",
        this.teoname,
        serverName
      );

      // Listen for WebRTC on open event
      this.teoweb.onOpen(function () {
        console.debug("onOpen");

        // Set online
        that.online = true;
        that.disconnected = false;

        // Send emit online event
        that.$emit("online", true);

        // Set login information
        let userkey = localStorage.userkey || sessionStorage.userkey;
        that.teoweb.sendCmd(cmdLoginUserSet + userkey);

        // Request connect component data from WebRTC server
        that.teoweb.sendCmd(cmdName);
        that.teoweb.sendCmd(cmdUptime);
        that.teoweb.sendCmd(cmdVersion);
        that.teoweb.sendCmd(cmdClients);
        that.teoweb.subscribeCmd(cmdClients);
      });

      // Listen for WebRTC on close event
      this.teoweb.onClose(function (b) {
        console.debug("onClose");

        // Set offline
        that.online = false;
        if (b === true) {
          that.disconnected = true;
          that.reconnect();
        }

        // Send emit online event
        that.$emit("online", false);
      });

      // Listen for WebRTC data from server
      this.teoweb.addReader(function (gw, data) {
        switch (gw.command) {
          case cmdClients:
            that.clients = data;
            break;
          case cmdName:
            that.name = data;
            localStorage.name = that.name;
            break;
          case cmdVersion:
            that.version = data;
            localStorage.version = that.version;
            break;
          case cmdUptime:
            that.uptime = data;
            break;
        }
        if (gw.command.startsWith(cmdLoginUserSet)) {
          let parts = gw.command.split("/");
          let userkey = parts[1];
          that.teoweb.token = userkey;
        }
      });
    },

    /** Reconnect to Teonet server */
    reconnect() {
      // TODO: Implement reconnect or Update teoweb
      // location.reload();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.online,
.online a {
  color: #42b983 !important;
}
.offline,
.offline a {
  color: red !important;
}
.online a,
.offline a {
  text-decoration: none;
}
</style>
