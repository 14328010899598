<template>
  <div v-if="online.online">
    <span class="badge bg-success">Online</span> in {{ online.level }}
  </div>
  <!-- <div v-else class="text-danger">offline</div> -->
</template>

<script>
const allGamesLabel = "All games";
const cmdPlayerOnline = "player.online/";

export default {
  name: "PlayerOnline",

  props: {
    game: String,
    sub: String,
  },

  data() {
    return {
      online: { online: false, level: "" },
    };
  },

  mounted() {
    let that = this;

    // Skip if game is all games
    if (!this.game || this.game === allGamesLabel) {
      return;
    }

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }

      // Process answer to cmdPlayerOnline command
      switch (gw.command) {
        case cmdPlayerOnline + that.game + "/" + that.sub:
          that.online = JSON.parse(data);
          break;
      }
    });

    // Request player online status when logged in
    this.teoweb.whenLogin(() => {
      that.teoweb.sendCmd(cmdPlayerOnline + that.game + "/" + that.sub);
    });
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
  },
};
</script>
