<!-- Dastland games component -->
<template>
  <!-- Games dropdown -->
  <div v-if="allGames" class="dropdown">
    <button
      class="btn btn-outline-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ current }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li v-for="(game, index) in games" :key="index">
        <a class="dropdown-item" @click="setCurrent(game)">{{ game }}</a>
      </li>
    </ul>
  </div>
  <!-- Games text -->
  <div v-else v-show="false" class="btn border rounded text-secondary">
    {{ game }}
  </div>
</template>

<script>
const allGamesLabel = "All games";

export default {
  name: "DadminGames",

  props: {
    selectgame: String,
    game: String,
    sub: String,
  },

  data: function () {
    return {
      current: allGamesLabel,
      games: [],
      allGames: true,
    };
  },

  emmits: ["game"],

  mounted() {
    let that = this;
    const cmdGames = "games.list/";

    // Check and Set all games mode if game is empty or equals to "All games"
    this.allGames = !this.game || this.game === allGamesLabel;

    // Select current game
    if (this.selectgame) {
      this.current = this.selectgame;
    }

    // Get sub from parameters (props)
    let sub = "";
    if (that.sub) {
      sub = that.sub;
    }

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }

      // Process answer to cmdGames command
      switch (gw.command) {
        case cmdGames + sub:
          // If sub parameter is set
          if (that.sub) {
            that.games = JSON.parse(data);
            if (that.games.length) {
              that.setCurrent(that.games[0]);
            } else {
              // that.setCurrent("");
            }
            return;
          }

          // If sub parameter is not set and all games label to games list
          that.games = [allGamesLabel].concat(JSON.parse(data));
      }
    });

    // Send cmdGames command with parameter name to WebRTC server
    this.teoweb.whenLogin(() => {
      if (that.allGames) {
        that.teoweb.sendCmd(cmdGames + sub);
      }
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    setCurrent(current) {
      this.current = current;
      this.$emit("game", current);
    },
  },
};
</script>
